/* * {
    margin: 0;
    padding: 0;
    font-family: "IM Fell English", -apple-system, BlinkMacSystemFont,
        "Space Grotesk", "EB Garamond", "Helvetica Neue", sans-serif;
} */

/* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
} */

/* .jumbotron {
  position: absolute;
  top: 40%;
  left: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
  align-self: center;
  font-family: "Lobster";
  color: #fff;
  filter: drop-shadow(3px 3px 1px #73d4ff);
} */

#tagline {
    font-size: 2rem;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(0, 60, 90);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#dialog {
    bottom: 0;
}

.babylonGrid * {
    height: "600px";
}

/* Draw a line underneath links */
:root {
    --color-primary: #7644ff;
    --color-secondary: #5edba5;
    /* --color-primary: #463191;
    --color-secondary: #5edba5; */
}
a {
    color: "white";
    text-decoration: none;
    background-color: transparent;
    position: relative;
}
a::after {
    background: linear-gradient(
        120deg,
        #5edba5 20.69%,
        #1c99fe 50.19%,
        var(--color-primary) 79.69%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color-primary)', endColorstr='var(--color-secondary)',GradientType=1 );
}
a::after,
a {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
a:active,
a {
    transition: all 0.4s ease-in-out 0s;
}
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}
a::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
}
a:hover::after {
    width: 100%;
}
a:hover {
    color: var(--color-primary);
}
