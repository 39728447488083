.App {
    /* text-align: center; */
    display: table;
    width: 100%;
    background: linear-gradient(rgba(34, 50, 61, 0.7), rgba(34, 50, 61, 0.7)),
        url(../assets/AdobeStock_25662758.svg);
    /* background-image: url(../assets/AdobeStock_25662758.svg); */
    background-size: cover;
    background-position: 0% 35%;
    height: 100vh;
}

.App-link {
    color: rgb(112, 76, 182);
    text-decoration: none;
}

.links {
    margin-left: auto;
}

#navbar-menu * {
    color: navy;
    text-decoration: none;
}

#toolbar {
    min-height: 30px;
}
